<template>
    <v-container class="pt-8">
        <h1 class="display-1">Política de cookies</h1>
        <v-card v-for="paragraph in paragraphs"
                class="pa-5 mt-4"
        >
            <v-card-title class="headline">{{paragraph.title}}</v-card-title>
            <v-card-text class="title font-weight-regular">
                <template v-for="line in paragraph.text.split('\n')">{{line}}<br></template>
            </v-card-text>
        </v-card>

        <v-card class="pa-5 mt-4">
            <v-card-title class="headline">Links</v-card-title>
            <template v-for="link in links">
                <v-card-text class="headline">{{link.title}}</v-card-text>
                <a :href="link.link" class="subtitle-1 font-weight-regular ml-8">{{link.link}}</a>
            </template>
        </v-card>

        <v-card class="pa-5 mt-4">
            <v-card-text class="title font-weight-regular">Si
                tens qualsevol
                dubte sobre
                aquesta Política
                de Cookies,
                pots contactar
                amb nosaltres
                enviant-nos
                un mail
                a
                contacte@examenselectivitat.cat.
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
export default {
  name: 'Cookies',
  data: () => ({
    paragraphs: [
      {
        title: 'Política de cookies',
        text: `Si vols saber més sobre l'ús de cookies que realitza aquest lloc web https://examenselectivitat.cat, estàs en el lloc indicat. A continuació, t'explicarem què són exactament les cookies; quin tipus de cookies utilitzem i per a què; i com pots exercir el teu dret per a configurar el teu navegador i desestimar l'ús de qualsevol d'elles.
Això sí, has de saber, que si decideixes no utilitzar algunes cookies, aquest lloc web pot no funcionar perfectament, afectant la teva experiència d'usuari.`
      },
      {
        title: 'Què és una cookie?',
        text: `Una cookie és un fitxer que es descarrega en el teu ordinador en accedir a determinades pàgines web o blogs.
Les cookies permeten a aquesta pàgina, entre altres coses, emmagatzemar i recuperar informació sobre els teus hàbits de navegació o del teu equip, i depenent de la informació que continguin i de la forma en què utilitzis el teu equip, poden utilitzar-se per a reconèixer-te.
El navegador de l'usuari memoritza cookies en el disc dur solament durant la sessió actual ocupant un espai de memòria mínim i no perjudicant l'ordinador. Les cookies no contenen cap classe d'informació personal específica, i la majoria de les mateixes s'esborren del disc dur en finalitzar la sessió de navegador (les denominades cookies de sessió).
La majoria dels navegadors accepten com a estàndard a les cookies i, amb independència d'aquestes, permeten o impedeixen en els ajustos de seguretat les cookies temporals o memoritzades.
Les cookies s'associen al navegador, no a la persona, per la qual cosa no solen emmagatzemar informació sensible sobre tu com a targetes de crèdit o dades bancàries, fotografies o informació personal, etc. Les dades que guarden són de caràcter tècnic, estadístics, preferències personals, personalització de continguts, etc.`
      },
      {
        title: 'Acceptació de les cookies: Normativa vigent',
        text: `En accedir a aquest lloc web, i d'acord amb la normativa vigent en matèria de protecció de dades, t'informem de l'ús de cookies, donant-te l'opció d'acceptar-les expressament i d'accedir a més informació a través d'aquesta Política de Cookies.
Has de saber que, en el cas de continuar navegant, estaràs prestant el teu consentiment per a l'ús d'aquestes cookies. Però, en qualsevol moment, podràs canviar d'opinió i bloquejar la seva utilització a través del teu navegador.
Per a la teva total tranquil·litat, aquest lloc web compleix amb l'estipulat en la normativa vigent en relació amb l'ús de les cookies i les teves dades personals:
El reglament LSSI-CE (Llei de la societat de la informació i del comerç electrònic)
El RGPD (Reglament (UE) 2016/679 del Parlament Europeu i del Consell de 27 d'abril de 2016 relatiu a la protecció de les persones físiques), que unifica la regulació del tractament de les dades personals als països de la UE.
Aquesta Política de Cookies podria modificar-se en qualsevol moment per a adaptar-se a novetats normatives o canvis en les nostres activitats, sent vigent la que a cada moment es trobi publicada en la web.`
      },
      {
        title: 'Tipus de cookies',
        text: 'Per a poder oferir-te una millor experiència d\'usuari, obtenir dades analítiques, emmagatzemar i recuperar informació sobre els teus hàbits de navegació o del teu equip i desenvolupar la seva activitat, aquest lloc web https://examenselectivitat.cat, utilitza cookies pròpies i de tercers.  '
      },
      {
        title: 'Quins tipus de cookies utilitza aquest lloc web?',
        text: `Cookies tècniques: Són aquelles que permeten a l'usuari la navegació a través d'una pàgina web, plataforma o aplicació i la utilització de les diferents opcions o serveis que en ella existeixin com, per exemple, controlar el trànsit i la comunicació de dades, identificar la sessió, accedir a parts d'accés restringit, recordar els elements que integren una comanda, realitzar el procés de compra d'una comanda, realitzar la sol·licitud d'inscripció o participació en un esdeveniment, utilitzar elements de seguretat durant la navegació, emmagatzemar continguts per a la difusió de vídeos o so o compartir continguts a través de xarxes socials.
Cookies de personalització: Són aquelles que permeten a l'usuari accedir al servei amb algunes característiques de caràcter general predefinides en funció d'una sèrie de criteris en el terminal de l'usuari com per exemple serien l'idioma, el tipus de navegador a través del qual accedeix al servei, la configuració regional des d'on accedeix al servei, etc.
Cookies d'anàlisis: Són aquelles que ben tractades per nosaltres o per tercers, ens permeten quantificar el nombre d'usuaris i així realitzar el mesurament i anàlisi estadística de la utilització que fan els usuaris del servei ofert. Per a això s'analitza la teva navegació en la nostra pàgina web amb la finalitat de millorar l'oferta de productes o serveis que li oferim.
Cookies publicitàries: Són aquelles que, ben tractades per nosaltres o per tercers, ens permeten gestionar de la forma més eficaç possible l'oferta dels espais publicitaris que hi ha en la pàgina web, adequant el contingut de l'anunci al contingut del servei sol·licitat o a l'ús que realitzi de la nostra pàgina web. Per a això podem analitzar els teus hàbits de navegació en Internet i podem mostrar-te publicitat relacionada amb el teu perfil de navegació.
Cookies de publicitat comportamental: Són aquelles que permeten la gestió, de la forma més eficaç possible, dels espais publicitaris que, en el seu cas, l'editor hagi inclòs en una pàgina web, aplicació o plataforma des de la qual presta el servei sol·licitat. Aquestes cookies emmagatzemen informació del comportament dels usuaris obtinguda a través de l'observació continuada dels seus hàbits de navegació, la qual cosa permet desenvolupar un perfil específic per a mostrar publicitat en funció d'aquest.
Cookies de tercers: Aquesta web https://examenselectivitat.cat pot utilitzar serveis de tercers que, per compte de Google, recopilaran informació amb finalitats estadístics, d'ús del lloc per part de l'usuari i per a la prestació d'altres serveis relacionats amb l'activitat del lloc web i altres serveis d'Internet.
En particular, aquest lloc web utilitza Google Analytics, un servei analític de web prestat per Google, Inc. amb domicili als Estats Units amb seu central en 1600 Amphitheatre Parkway, Mountain View, Califòrnia 94043. Per a la prestació d'aquests serveis, Google utilitza cookies que recopilen la informació, inclosa l'adreça IP de l'usuari, que serà transmesa, tractada i emmagatzemada per Google en els termes fixats en la web Google.com. Això inclou la possible transmissió d'aquesta informació a tercers per raons d'exigència legal o quan dits tercers processin la informació per compte de Google.`
      },
      {
        title: 'Gestionar i rebutjar l\'ús de cookies',
        text: `En qualsevol moment, pots adaptar la configuració del navegador per a gestionar, desestimar l'ús de cookies i ser notificat abans que es descarreguin.
També pots adaptar la configuració de manera que el navegador rebutgi totes les cookies, o únicament les cookies de tercers. I també pots eliminar qualsevol de les cookies que ja es trobin en el teu equip.
Per a això, has de tenir en compte que hauràs d'adaptar per separat la configuració de cada navegador i equip que utilitzis ja que, com t'hem comentat anteriorment les cookies s'associen al navegador, no a la persona.
`
      }
    ],
    links: [
      {
        title: 'Google Chrome',
        link: 'https://support.google.com/chrome/answer/95647?hl=es-419'
      },
      {
        title: 'Internet Explorer',
        link: 'https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies#ie=*ie-10'
      },
      {
        title: 'Mozilla Firefox',
        link: 'https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectlocale=es&redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we'
      },
      {
        title: 'Apple Safari',
        link: 'https://support.apple.com/es-es/ht201265'
      }
    ]
  })
}
</script>
